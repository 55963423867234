<template>
  <div>
    <b-row>
      <b-col
        v-for="radial in satisfactionRadial"
        :key="radial.title"
        lg="3"
        md="6"
      >
        <indice-satisfactionRadial
          v-if="radial.loading === false"
          :title="radial.title"
          :value="radial.value"
          :percent="radial.percent"
          :loading="loading"
          :color="radialColor(radial.value)"
        />
      </b-col>

      <b-col lg="6">
        <support-tracker
          v-if="supportTrackerByType.loading === false"
          ref="supportTrackerByType"
          :title="supportTrackerByType.title"
          :all="supportTrackerByType.total"
          :datas="supportTrackerByType.seriesData"
          :labels="supportTrackerByType.seriesLabel"
        />
      </b-col>
    </b-row>

    <b-row v-if="isBetrancourt">
      <b-col lg="6">
        <support-tracker
          v-if="supportTrackerBySubType.loading === false"
          ref="supportTrackerBySubType"
          :title="supportTrackerBySubType.title"
          :all="supportTrackerBySubType.total"
          :datas="supportTrackerBySubType.seriesData"
          :labels="supportTrackerBySubType.seriesLabel"
        />
      </b-col>

      <b-col
        v-for="delai in delaisStats"
        :key="delai.title"
        lg="3"
        md="6"
      >
        <b-card
          v-if="delai.loading === false"
          class="text-center"
          :bg-variant="delai.bg"
        >
          <b-avatar
            class="mb-3 mt-3"
            :variant="`light-${delai.color}`"
            size="90"
          >
            <feather-icon
              size="42"
              :icon="delai.icon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{ delai.days }} {{ $t('DAYS') }} {{ delai.hours }} {{ $t('HOURS') }}
            </h2>
            <span>{{ $t(delai.title) }}</span>
          </div>
        </b-card>
      </b-col>
      <!--
      <b-col
        v-for="(item, index) in satisfactionEvolution"
        :key="index"
        md="6"
      >
        <satisfaction-line-area
          v-if="item.loading === false"
          :series="item.series"
          :title="item.title"
          :sub-title="item.subtitle"
          :axe-x-title="statisfactionEvolutionOption.axeXTitle"
        />
      </b-col>
      -->
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
// import { DateTime } from 'luxon'

import indiceSatisfactionRadial from './components/indiceSatisfaction.vue'
// import satisfactionLineArea from './components/satisfactionLineArea.vue'
import supportTracker from './components/supportTracker.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BAvatar,
    indiceSatisfactionRadial,
    // satisfactionLineArea,
    supportTracker,
  },
  data() {
    return {
      loading: false,
      isBetrancourt: true,
      supportTrackerByType: {
        loading: true,
        title: 'STAT_REPARTITION_RECLAMATION_BY_TYPE',
        total: 0,
        chartColors: [],
        seriesData: [],
        seriesLabel: [],
        seriesLabelsUntranslate: [],
      },
      supportTrackerBySubType: {
        loading: true,
        title: 'STAT_REPARTITION_RECLAMATION_BY_SUBTYPE',
        total: 0,
        chartColors: [],
        seriesData: [],
        seriesLabel: [],
        seriesLabelsUntranslate: [],
      },
      satisfactionRadial: [
        {
          title: 'SATISFACTION_RADIAL_GLOBAL',
          value: [0],
          percent: 0,
          loading: true,
        },
        {
          title: 'SATISFACTION_RADIAL_USER',
          value: [0],
          percent: 0,
          loading: true,
        },
      ],
      delaisStats: [
        {
          title: 'PRIS_CHARGE_DELAI_STAT',
          bg: 'gradient-success',
          days: 0,
          hours: 0,
          color: 'light-success',
          loading: true,
          icon: 'ClockIcon',
        },
        {
          title: 'CLOSED_CHARGE_DELAI_STAT',
          bg: 'gradient-success',
          days: 0,
          hours: 0,
          color: 'light-success',
          loading: true,
          icon: 'ClockIcon',
        },
      ],
      /*
      satisfactionEvolution: [
        {
          title: 'SATISFACTION_EVOLUTION',
          subtitle: 'SATISFACTION_EVOLUTION_SUBTITLE',
          series: [
            {
              name: this.$i18n.t('SATISFACTION_EVOLUTION_GLOBAL_SERIES'),
              data: [],
            },
            {
              name: this.$i18n.t('SATISFACTION_EVOLUTION_USER_SERIES'),
              data: [],
            },
          ],
          loading: true,
        },
      ],
      statisfactionEvolutionOption: {
        axeXTitle: [],
      },
      */
    }
  },
  mounted() {
    this.loading = true

    if (JSON.parse(localStorage.userData).company === null) {
      this.isBetrancourt = true
    } else {
      this.isBetrancourt = false
    }

    /*
    this.statisfactionEvolutionOption.axeXTitle = [
      DateTime.now().minus({ month: 2 }).toFormat('M'),
      DateTime.now().minus({ month: 1 }).toFormat('M'),
      DateTime.now().toFormat('M'),
    ]
    */

    this.$store.dispatch('reclamations/statsCompany')
      .then(r => {
        this.supportTrackerByType.total = r.all
        this.supportTrackerByType.seriesData = r.statsByType.seriesTotal
        this.supportTrackerByType.seriesLabelsUntranslate = r.statsByType.seriesLabel
        this.supportTrackerByType.seriesLabel = r.statsByType.seriesLabel

        this.supportTrackerByType.loading = false

        this.supportTrackerBySubType.total = r.all
        this.supportTrackerBySubType.seriesData = r.statsBySubType.seriesTotal
        this.supportTrackerBySubType.seriesLabelsUntranslate = r.statsBySubType.seriesLabel
        this.supportTrackerBySubType.seriesLabel = r.statsBySubType.seriesLabel
        this.supportTrackerBySubType.loading = false

        if (JSON.parse(localStorage.userData).company === null) {
          if (r.delaiPriseEnCharge) {
            this.delaisStats[0].days = r.delaiPriseEnCharge.d
            this.delaisStats[0].hours = r.delaiPriseEnCharge.h

            if (r.delaiPriseEnCharge.d > 3 && r.delaiPriseEnCharge.d <= 6) {
              this.delaisStats[0].bg = 'gradient-warning'
              this.delaisStats[0].color = 'light-warning'
            } else if (r.delaiPriseEnCharge.d > 6) {
              this.delaisStats[0].bg = 'gradient-danger'
              this.delaisStats[0].color = 'light-danger'
            }
            this.delaisStats[0].loading = false

            this.delaisStats[1].days = r.delaiCloture.d
            this.delaisStats[1].hours = r.delaiCloture.h
            if (r.delaiCloture.d > 3 && r.delaiCloture.d <= 6) {
              this.delaisStats[1].bg = 'gradient-warning'
              this.delaisStats[1].color = 'light-warning'
            } else if (r.delaiCloture.d > 6) {
              this.delaisStats[1].bg = 'gradient-danger'
              this.delaisStats[1].color = 'light-danger'
            }
            this.delaisStats[1].loading = false
          }
        }
      })

    if (JSON.parse(localStorage.userData).company === null) this.isBetrancourt = true

    this.$store.dispatch('reclamations/global_stats')
      .then(r => {
        this.satisfactionRadial[0].indice = parseFloat(r.data.indiceSatisfactionGlobal[0].AVGSatisfaction).toFixed(2)
        this.satisfactionRadial[0].value = [parseFloat(r.data.indiceSatisfactionGlobal[0].AVGSatisfaction * 20).toFixed(2)]
        this.satisfactionRadial[0].loading = false

        this.satisfactionRadial[1].indice = parseFloat(r.data.indiceSatisfactionByUser[0].AVGSatisfaction).toFixed(2)
        this.satisfactionRadial[1].value = [parseFloat(r.data.indiceSatisfactionByUser[0].AVGSatisfaction * 20).toFixed(2)]
        this.satisfactionRadial[1].loading = false

        /*
        this.statisfactionEvolutionOption.axeXTitle.forEach((mois, index) => {
          this.satisfactionEvolution[0].series[0].data[index] = 0
          this.satisfactionEvolution[0].series[1].data[index] = 0

          r.data.indiceSatisfactionGlobalLast3Month.forEach(valeur => {
            if (parseInt(valeur.closedMonth, 10) === parseInt(mois, 10)) {
              this.satisfactionEvolution[0].series[0].data[index] = parseFloat(valeur.AVGSatisfaction).toFixed(2)
            }
          })

          r.data.indiceSatisfactionUserLast3Month.forEach(valeur => {
            if (parseInt(valeur.closedMonth, 10) === parseInt(mois, 10)) {
              this.satisfactionEvolution[0].series[1].data[index] = parseFloat(valeur.AVGSatisfaction).toFixed(2)
            }
          })
        })

        this.statisfactionEvolutionOption.axeXTitle = [
          DateTime.now().minus({ month: 2 }).setLocale(this.$i18n.locale).toFormat('MMMM'),
          DateTime.now().minus({ month: 1 }).setLocale(this.$i18n.locale).toFormat('MMMM'),
          DateTime.now().setLocale(this.$i18n.locale).toFormat('MMMM'),
        ]

        this.satisfactionEvolution[0].loading = false
        */
        this.loading = false
      }).catch(() => {
        this.$router.push('/pages/error-404')
        this.loading = false
      })
  },
  methods: {
    radialColor(value) {
      if (parseFloat(value) < 40) return $themeColors.danger
      if (parseFloat(value) < 75) return $themeColors.warning
      return $themeColors.success
    },
  },
}
</script>

<style lang="scss">
  .card:not(.no-min-height) {
    min-height: 360px;
  }
</style>
