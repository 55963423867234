<template>
  <b-card
    no-body
  >
    <b-card-header>
      <h4 class="mb-0">
        {{ $t(title) }}
      </h4>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="250"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="value"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const $strokeColor = '#ebe9f1'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    value: {
      type: Array,
      default: () => [0],
    },
    percent: {
      type: Number,
      default: () => 0,
    },
    color: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.color],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                fontSize: '2.86rem',
                fontWeight: '600',
                formatter(val) {
                  return parseFloat(val * 0.05).toFixed(2)
                },
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
}
</script>
