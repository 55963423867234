<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ $t(title) }}
    </b-card-title>
    <b-card-sub-title class="mb-2">
      {{ $t('STAT_ALL_RECLAMATION_COMPANY') }} : {{ all }}
    </b-card-sub-title>

    <vue-apex-charts
      ref="chart"
      type="pie"
      height="250"
      :options="donutChart.chartOptions"
      :series="donutChart.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardSubTitle,
    BCardTitle,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    all: {
      type: Number,
      default: () => 0,
    },
    colors: {
      type: Array,
      default: () => [
        '#ffe700',
        '#00d4bd',
        '#826bf8',
        '#2b9bf4',
        '#FFA1A1',
      ],
    },
    datas: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      donutChart: {
        series: this.datas,
        chartOptions: {
          legend: {
            show: true,
            position: 'left',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: this.colors,
          labels: [],
          dataLabels: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  beforeMount() {
    this.donutChart.chartOptions.labels = this.labels.map(el => this.$i18n.t(el))
  },
  mounted() {
    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale !== oldLocale) {
          this.donutChart.chartOptions.labels = this.labels.map(el => this.$i18n.t(el))
          this.$refs.chart.updateOptions(this.donutChart.chartOptions)
        }
      },
    )
  },
}
</script>
